* {
  color: white;
  font-family: 'Manrope';
}

.red-color {
  color: red;
}

.text-underline {
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
}

.separator {
  border: 1px solid #333;
  height: 0;
}

.form-wrapper {
  position: fixed;
  height: 100vh;
  background-color: #00000066;
  border-radius: 5px;
  max-width: 900px;
  width: 100%;
  z-index: 0;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  margin-left: calc(50% - 450px);

  @media (max-width: 700px) {
    margin-left: 0;
  }
}

.description {
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.description-14 {
  @extend .description;
  font-size: 14px;
}

.description-16 {
  @extend .description;
  font-size: 16px;
}

.description-20 {
  @extend .description;
  font-size: 20px;
  
  @media (max-width: 700px) {
    font-size: 12px;
  }
}

.description-30 {
  @extend .description;
  font-size: 30px;
}

.description-40 {
  @extend .description;
  font-size: 40px;

  @media (max-width: 700px) {
    font-size: 20px;
  }
}

.caption {
  font-style: bold;
  font-weight: 700;
  line-height: 120%;
}

.caption-20 {
  @extend .caption;
  font-size: 20px;
}

.caption-40 {
  @extend .caption;
  font-size: 40px;

  @media (max-width: 700px) {
    font-size: 20px;
  }
}

.caption-50 {
  @extend .caption;
  font-size: 50px;
}

.caption-60 {
  @extend .caption;
  font-size: 60px;

  @media (max-width: 700px) {
    font-size: 30px;
  }
}