.modal-geo-block {
    color: #FFFFFF;
    display: block;
    position: relative;
    font-size: 30px;
    width: 1044px;
    height: 467px;
    background: #000000;
    border: 6px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 0px;

    @media (max-width: 1400px) {
        width: 90vw;
    }

    @media (max-width: 750px) {
        width: 100vw;
        height: 100vh;
    }

    .content {
        margin-top: 104px;
        text-align: center;

        @media (max-width: 750px) {
            margin-top: 200px;
        }

        .description {
            margin-bottom: 10px;
            font-weight: 400;
            font-size: 30px;
            line-height: 133%;
            text-align: center;
            width: 908px;
            margin-left: 69px;

            @media (max-width: 1120px) {
                margin: 0 5% 0 5%;
                width: 90%;
                font-size: 25px;
            }

            @media (max-width: 750px) {
                margin: 0 5% 0 5%;
                width: 90%;
                font-size: 18px;
                line-height: 150%;
                margin-top: 5%;
            }
        }

        .title {
            margin-bottom: 30px;
            font-weight: 700;
            font-size: 30px;
            line-height: 133%;
            text-align: center;

            @media (max-width: 1120px) {
                margin: 0 5% 0 5%;
                font-size: 25px;
            }

            @media (max-width: 750px) {
                font-size: 18px;
                line-height: 150%;
                margin-top: 5%;
            }
        }

        .proceed-button {
            margin-top: 50px;
            cursor: pointer;
            width: 270px;
            height: 70px;
            background: #FF0000;
            border-radius: 100px;
            font-weight: 700;
            font-size: 40px;
            line-height: 175%;
            border: none;

            @media (max-width: 750px) {
                width: 165px;
                height: 53px;
                margin-top: 30px;
                font-size: 16px;
            }
        }

        .close-button {
            color: #FFFFFF;
            background-color: #FF0000;
            margin-top: 50px;
            cursor: pointer;
            width: 322px;
            height: 70px;
            text-decoration: underline;
            border-radius: 100px;
            font-weight: 700;
            font-size: 40px;
            line-height: 175%;
            border: none;

            @media (max-width: 750px) {
                width: 160px;
                height: 30px;
                margin-top: 30px;
                font-size: 16px;
            }
        }
    }
}

.landing-center-content {
    font-family: "Manrope";
    color: white;
    margin-top: 11vh;
    line-height: 140%;
    text-decoration: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0;

    @media (max-width: 1200px) {
        margin-top: 20vh;
    }

    .landing-page-title {
        font-size: 60px;
        line-height: 140%;
        font-weight: 900;
        text-align: center;
        font-style: normal;

        @media (max-width: 1200px) {
            font-size: 35px;
        }

        @media (max-width: 700px) {
            font-size: 28px;
        }

        @media (max-width: 400px) {
            font-size: 22px;
        }
    }

    .landing-page-sign-up-button {
        text-align: center;
        margin-top: 10px;
        text-decoration: none;
        font-size: 40px;
        line-height: 150%;
        font-weight: 300;
        cursor: pointer;

        @media (max-width: 1200px) {
            font-size: 35px;
        }

        @media (max-width: 700px) {
            font-size: 28px;
        }

        @media (max-width: 400px) {
            font-size: 22px;
        }
    }

    .landing-page-let-go-button {
        margin-top: 5vh;
        width: 400px;
        height: 76px;
        background: #25D366;
        border-radius: 100px;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        font-size: 20px;
        line-height: 35px;
        font-weight: normal;
        font-weight: 700;

        @media (max-width: 700px) {
            height: 60px;
            width: 300px;
            font-size: 18px;
        }

        .landing-page-let-go-text {
            padding-top: 20px;

            @media (max-width: 700px) {
                padding-top: 13px;
            }
        }
    }


    .landing-page-roku-image {
        width: 540px;
        height: 324px;

        @media (max-width: 1200px) {
            width: 360px;
            height: 216px;
        }

        @media (max-width: 700px) {
            width: 270px;
            height: 162px;
        }
    }

    .landing-page-roku-movie-image {
        width: 457px;
        height: 209px;

        @media (max-width: 1200px) {
            width: 304px;
            height: 139px;
        }

        @media (max-width: 700px) {
            width: 265px;
            height: 121px;
        }
    }

    .landing-page-ch-play-button {
        display: block;
        margin-top: 1vh;
        width: 400px;
        height: 76px;
        background: #25D366;
        border-radius: 10px;
        text-decoration: none;
        text-align: center;
        cursor: pointer;

        @media (max-width: 800px) {
            height: 60px;
            width: 300px;
        }

        @media (max-width: 300px) {
            height: 60px;
            width: 250px;
        }

        .landing-page-ch-play {
            width: 161px;
            height: 53px;
            margin-top: 15px;

            @media (max-width: 800px) {
                margin-top: 8px;
            }
        }
    }

    .landing-page-app-store-button {
        display: block;
        margin-top: 1vh;
        width: 400px;
        height: 76px;
        background: #25D366;
        border-radius: 10px;
        text-decoration: none;
        text-align: center;
        cursor: pointer;

        @media (max-width: 800px) {
            width: 244px;
            height: 55px;
        }

        .landing-page-app-store {
            width: 153px;
            height: 45px;
            margin-top: 15px;

            @media (max-width: 800px) {
                margin-top: 5px;
            }
        }
    }

    .landing-page-tmh-profile-logo {
        width: 68px;
        height: 68px;
        margin-top: 15px;

        @media (max-width: 800px) {
            margin-top: 10px;
            width: 68px;
            height: 68px;
        }
    }

    .landing-page-play-store {
        font-weight: 700;
        font-size: 20px;

        @media (max-width: 800px) {
            font-size: 15px;
        }
    }
}