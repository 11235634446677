.wrapper-banner {
  background: url("./background.jpg");
  background-size: cover;
  position: fixed;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  @media (max-width: 1200px) {
    // background-repeat: repeat-y;
  }

  @media (max-width: 600px) {
    background-size: cover;
  }
}

.wrapper-banner-gradient {
  background: url("./gradient-background.png");
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  height: 100vh;
  background-position: 50% 50%;
  background-size: 100% 100%;
}

.navbar-banner {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.logo {
  display: block;
  height: 119px;
  width: 119px;
  margin-top: 46px;
  margin-left: 67px;

  @media (max-width: 1200px) {
    margin-top: 10px;
    margin-left: 39px;
  }

  @media (max-width: 900px) {
    height: 85px;
    width: 85px;
    margin-top: 23px;
    margin-left: 39px;
  }
}

.login-button {
  display: block;
  width: 130px;
  height: 58px;
  opacity: 0.8;
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border-radius: 100px;
  cursor: pointer;
  margin-top: 66px;
  margin-right: 60px;
  text-decoration: none;
  background-color: transparent;

  @media (max-width: 1200px) {
    margin-top: 20px;
    margin-right: 37px;
  }

  @media (max-width: 900px) {
    margin-top: 33px;
    margin-right: 37px;
    width: 99px;
    height: 51px;
  }
}

.login-text {
  text-align: center;
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 120%;
  color: #FFFFFF;
  margin-top: 17px;

  @media (max-width: 900px) {
    font-size: 14px;
    margin-top: 15px;
  }
}