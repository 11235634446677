.wrapper-footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
  text-align: center;
  align-items: center;
  margin-top: 50px;

  @media (max-width: 800px) {
    flex-flow: wrap;
    flex-direction: column;
    margin-top: 20px;
  }

  a {
    display: inline-block; 
    padding-right: 15px;
    padding-left: 15px;
    font-family: "Manrope";
    font-size: 14px;
    line-height: 150%;
    font-weight: normal;
    color: white;
  
    a:visited {
      color: white;
    }
  
    @media (max-width: 800px) {
      .wrapper-footer a{
        font-size: 10px;
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }
}