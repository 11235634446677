.input-email-page-center-content {
    text-decoration: none;
    position: relative;
    text-align: left;

    margin-left: calc(50% - 450px);
    margin-top: 15vh;
    @media (max-width: 700px) {
        margin-left: 0;
    }

    .content {
        max-width: 642px;
        display: grid;
        gap: 20px;
        margin: 0 129px;
        @media (max-width: 700px) {
            margin: 0 25px;
            width: calc(100% - 50px);
        }

        input[type="email"] {
            margin-top: 20px;
            width: 592px;
            height: 70px;
            padding: 0 38px;
            background: #333333;
            border-radius: 100px;
            border: none;

            @media (max-width: 700px) {
                width: calc(100% - 50px);
                padding: 0 25px;
                height: 44px;
            }
        }

        .terms-block {
            display: flex;

            input[type="checkbox"] {
                float: right;
                margin-left: 30px;
                width: 50px;
                height: 50px;
                background: #333333;
                border: 1px solid #FFFFFF;
                backdrop-filter: blur(10px);
                border-radius: 5px;
                float: right;
                accent-color: #25D366;

                @media (max-width: 700px) {
                    width: 25px;
                    height: 25px;
                }
            }
        }

        button {
            margin-top: 40px;
            width: 620px;
            height: 70px;
            background: #25D366;
            border-radius: 100px;
            border: none;
            cursor: pointer;
            margin-top: 10vh;

            @media (max-width: 700px) {
                width: 299px;
                height: 70px;
                margin-left: calc(50% - 150px);
            }
        }

        button:disabled {
            cursor: default;
            background: #25D36681;
        }

        .separator {
            @media (max-width: 700px) {
                width: 80%;
                margin-left: 10%;
            }
        }
    }
}