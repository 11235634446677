.movie-page-center-content {
    text-decoration: none;
    position: relative;
    text-align: center;

    margin-left: calc(50% - 450px);
    margin-top: 15vh;
    @media (max-width: 700px) {
        margin-left: 0;
    }

    .content {
        max-width: 890px;
        display: grid;
        width: 100%;
        gap: 20px;
        margin: 0;

        @media (max-width: 700px) {
            width: 100%;
        }

        img {
            width: 457px;
            height: 209px;
            margin-left: calc(50% - 229px);

            @media (max-width: 700px) {
                width: 265px;
                height: 121px;
                margin-left: calc(50% - 133px);
            }
        }

        button {
            width: 400px;
            height: 76px;
            background: #25D366;
            border-radius: 100px;
            border: none;
            cursor: pointer;
            margin-left: calc(50% - 200px);
            margin-top: 8vh;

            @media (max-width: 700px) {
                width: 299px;
                height: 70px;
                margin-left: calc(50% - 150px);
            }
        }

        .separator {
            width: 80%;
            margin-left: 10%;
        }
    }
}